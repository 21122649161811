import React from 'react';

import { currencyCellText, dateFormat } from '../../helpers';

import Pill from '../Pill';
import ChevronRight from '../Icons/ChevronRight';

import styles from './priceHistoryTab.module.scss';

export const PriceRequestPriceHistoryColumns = [
  {
    Header: 'PR Type',
    accessor: 'type',
    Cell: (cellInfo: any) => {
      return (
        <Pill type="current" secondary>
          {cellInfo.value}
        </Pill>
      );
    }
  },
  {
    Header: 'Effective Date',
    accessor: (row: any) =>
      row.calendarId
        ? row.calendarId.effective_date
        : row.effective_date
        ? row.effective_date
        : row.price_effective_date,
    Cell: (cellInfo: any) => {
      return <Pill type="date">{dateFormat(cellInfo.value)}</Pill>;
    }
  },
  {
    Header: 'End Date',
    accessor: 'final_effective_end_date',
    Cell: (cellInfo: any) => {
      return (
        <span className={styles.endDateContainer}>
          <ChevronRight />
          <Pill type="date">{dateFormat(cellInfo.value)}</Pill>
        </span>
      );
    }
  },
  {
    Header: 'Prime Cost',
    accessor: 'prime_cost_per_case',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Domestic Charges',
    accessor: 'domestic_charges_per_case',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Duty Paid Cost',
    accessor: 'duty_paid_cost_per_case',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Est. WS Price as of...',
    accessor: 'estimated_price_per_su',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    },
    tipText: 'Estimated WholeSale Price as of Effective Date /SU'
  },
  {
    Header: 'Promotion Amount',
    accessor: 'promotion_amount_per_su',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Est. WS Price...',
    accessor: 'price_per_su',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    },
    tipText: 'Est. WS Price (Promotional WS Price if WPP) / SU'
  }
];
