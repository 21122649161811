import { useCallback } from 'react';
import { UploadedPriceRequest } from '../reducers/CSVBulkUploadSlice';

import { UserTypeEnum, fieldHasValue, fieldIsNotApplicable } from '../helpers';

import { SelectInputType } from '../types';
import { CalendarDate } from '../types/productTypes';

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD

interface UseFieldValidationProps {
  pcDates: CalendarDate[];
  wppDates: CalendarDate[];
  getProductInfo: any;
  user: any;
  currencyOptions: SelectInputType[] | undefined;
}

const useFieldValidation = ({
  pcDates,
  wppDates,
  getProductInfo,
  user,
  currencyOptions
}: UseFieldValidationProps) => {
  const isValidNumber = (value: any) => {
    return (
      typeof value === 'number' && /^\d+(\.\d{1,2})?$/.test(value.toString())
    );
  };
  const isValidNumberNonZero = (value: any) => {
    if (value <= 0) return false;
    return isValidNumber(value);
  };

  const getMatchedCalendarEntry = (
    prEffectiveDate: string,
    calendarDates: any
  ) => {
    const matchedIndex = calendarDates.findIndex((date: any) => {
      const calendarEffectiveDate = new Date(date.effectiveDate)
        .toISOString()
        .split('T')[0];
      return calendarEffectiveDate === prEffectiveDate;
    });

    return calendarDates[matchedIndex] || null;
  };

  return useCallback(() => {
    return {
      isValidPRType: ({ request_type }: UploadedPriceRequest) => {
        const validValues = ['PC', 'WPP'];
        if (user.type === UserTypeEnum.INTERNAL) {
          validValues.push('NEW');
        }
        return validValues.includes(request_type);
      },
      isValidEffectiveDateRequired: ({
        effective_date
      }: UploadedPriceRequest) => {
        return fieldHasValue(effective_date);
      },
      isValidEffectiveDateInternal: ({
        effective_date,
        request_type
      }: UploadedPriceRequest) => {
        const validationCalendar = request_type === 'PC' ? pcDates : wppDates;
        const isValidFormat = DATE_REGEX.test(effective_date);
        const prEffectiveDate: Date = new Date(`${effective_date}T00:00:00`);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Min Valid Date threshold, Current Date + 3 days
        const validThresholdDate = new Date(currentDate);
        validThresholdDate.setDate(currentDate.getDate() + 3);

        // Effective Date is atleast 3 days in the future
        const isValidDate = prEffectiveDate >= validThresholdDate;

        // Get matching LDB Calendar entry if available
        const matchedEffectiveDate = getMatchedCalendarEntry(
          effective_date,
          validationCalendar
        );

        const isValidEffectiveDate = isValidFormat && isValidDate;

        return {
          isValidEffectiveDate,
          matchedEffectiveDate
        };
      },
      isValidEffectiveDateExternal: ({
        effective_date,
        request_type
      }: UploadedPriceRequest) => {
        const validationCalendar = request_type === 'PC' ? pcDates : wppDates;

        const isValidFormat = DATE_REGEX.test(effective_date);

        // Get matching LDB Calendar entry if available
        const matchedEffectiveDate = getMatchedCalendarEntry(
          effective_date,
          validationCalendar
        );

        // Return calendar data to append to the
        // Price Request as well as validity status
        return {
          isValidEffectiveDate: isValidFormat && matchedEffectiveDate?.id,
          matchedEffectiveDate
        };
      },
      isValidSubmissionDeadline: (submissionDeadline: string) => {
        const currentDate = new Date();

        return currentDate < new Date(submissionDeadline);
      },
      isValidPrimeCostRequired: ({
        sku,
        request_type,
        prime_cost_per_case,
        foreign_prime_cost_per_case
      }: UploadedPriceRequest) => {
        if (request_type !== 'PC' || fieldHasValue(foreign_prime_cost_per_case))
          return true;

        const { country_of_export, is_cost_dpc } = getProductInfo(sku);
        const isImport = country_of_export !== 'CA';
        const isDPC = is_cost_dpc?.toUpperCase() === 'YES';
        const isRequiredField = isImport || (!isImport && !isDPC);

        if (!isRequiredField) return true;

        return isValidNumber(prime_cost_per_case);
      },
      isValidPrimeCostEmpty: ({
        sku,
        request_type,
        prime_cost_per_case,
        foreign_prime_cost_per_case
      }: UploadedPriceRequest) => {
        const { country_of_export, is_cost_dpc } = getProductInfo(sku);

        const isImport = country_of_export !== 'CA';
        const isDPC = is_cost_dpc?.toUpperCase() === 'YES';
        const isRequiredField = isImport || (!isImport && !isDPC);

        const primeCostHasValue =
          !fieldIsNotApplicable(prime_cost_per_case) &&
          fieldHasValue(prime_cost_per_case);

        const foreignPrimeCostHasValue =
          !fieldIsNotApplicable(foreign_prime_cost_per_case) &&
          fieldHasValue(foreign_prime_cost_per_case);

        if (
          (primeCostHasValue && request_type === 'WPP') ||
          (primeCostHasValue && foreignPrimeCostHasValue)
        )
          return false;

        if (fieldIsNotApplicable(prime_cost_per_case) && !isRequiredField)
          return true;

        return isRequiredField;
      },
      isValidDomesticChargesRequired: ({
        sku,
        request_type,
        domestic_charges_per_case
      }: UploadedPriceRequest) => {
        if (request_type !== 'PC') return true;

        const { country_of_export } = getProductInfo(sku);
        const isImport = country_of_export !== 'CA';
        if (!isImport) return true;

        return isValidNumber(domestic_charges_per_case);
      },
      isValidDomesticChargesEmpty: ({
        sku,
        request_type,
        domestic_charges_per_case
      }: UploadedPriceRequest) => {
        if (
          fieldIsNotApplicable(domestic_charges_per_case) ||
          !fieldHasValue(domestic_charges_per_case)
        ) {
          return true;
        }

        if (request_type === 'WPP') return false;

        const { country_of_export } = getProductInfo(sku);
        const isDomestic = country_of_export === 'CA';

        return !isDomestic;
      },
      isValidDutyPaidCostRequired: ({
        sku,
        request_type,
        duty_paid_cost_per_case
      }: UploadedPriceRequest) => {
        if (request_type !== 'PC') return true;

        const { country_of_export, is_cost_dpc } = getProductInfo(sku);
        const isDomestic = country_of_export === 'CA';
        const isNotDPC = is_cost_dpc === 'NO';

        if (!isDomestic || isNotDPC) return true;

        return isValidNumber(duty_paid_cost_per_case);
      },
      isValidDutyPaidCostEmpty: ({
        sku,
        request_type,
        duty_paid_cost_per_case
      }: UploadedPriceRequest) => {
        if (
          fieldIsNotApplicable(duty_paid_cost_per_case) ||
          !fieldHasValue(duty_paid_cost_per_case)
        ) {
          return true;
        }

        if (request_type === 'WPP') return false;

        const { country_of_export, is_cost_dpc } = getProductInfo(sku);
        const isDomestic = country_of_export === 'CA';
        const isDPC = is_cost_dpc?.toUpperCase() === 'YES';

        const requiresDPCvalue = isDomestic && isDPC;

        return requiresDPCvalue;
      },
      isValidPromotionAmountRequired: ({
        promotion_amount_per_su,
        request_type
      }: UploadedPriceRequest) => {
        if (request_type !== 'WPP') return true;

        return isValidNumberNonZero(promotion_amount_per_su);
      },
      isValidPromotionAmountEmpty: ({
        promotion_amount_per_su,
        request_type
      }: UploadedPriceRequest) => {
        if (request_type === 'WPP') return true;

        return !fieldHasValue(promotion_amount_per_su);
      },
      isValidCurrencyCode: ({ currency_label }: UploadedPriceRequest) => {
        if (!fieldHasValue(currency_label)) return true;
        const currencyCodeOptions = currencyOptions?.map(
          (option: SelectInputType) => option.value
        );

        const isValidCurrencyCode = currencyCodeOptions?.includes(
          currency_label || ''
        );

        return isValidCurrencyCode;
      },
      isValidFXDateValue: ({
        currency_label,
        currency_effective_date
      }: UploadedPriceRequest) => {
        if (!fieldHasValue(currency_effective_date)) return true;

        const currencyCodeHasValue = fieldHasValue(currency_label);

        return currencyCodeHasValue;
      },
      isValidFXDateFormat: ({
        currency_effective_date
      }: UploadedPriceRequest) => {
        if (!fieldHasValue(currency_effective_date) || !currency_effective_date)
          return true;

        const isValidFormat = DATE_REGEX.test(currency_effective_date);
        const fxDate = new Date(currency_effective_date);
        const isValidDate = !isNaN(fxDate.getTime());

        return isValidFormat && isValidDate;
      },
      isValidForeignPrimeValue: ({
        currency_label,
        currency_effective_date,
        foreign_prime_cost_per_case
      }: UploadedPriceRequest) => {
        if (!fieldHasValue(foreign_prime_cost_per_case)) return true;

        const currencyCodeHasValue = fieldHasValue(currency_label);
        const currencyFxDateHasValue = fieldHasValue(currency_effective_date);

        return currencyCodeHasValue && currencyFxDateHasValue;
      },
      isValidForeignPrimeFormat: ({
        foreign_prime_cost_per_case
      }: UploadedPriceRequest) => {
        if (
          !fieldHasValue(foreign_prime_cost_per_case) ||
          !foreign_prime_cost_per_case
        )
          return true;

        return isValidNumber(foreign_prime_cost_per_case);
      },
      isValidAllFxFields: ({
        currency_label,
        currency_effective_date,
        foreign_prime_cost_per_case
      }: UploadedPriceRequest) => {
        const foreignPrimeHasValue = fieldHasValue(foreign_prime_cost_per_case);
        const currencyCodeHasValue = fieldHasValue(currency_label);
        const currencyFxDateHasValue = fieldHasValue(currency_effective_date);

        // Return all fields have value OR all fields are empty
        return (
          (currencyCodeHasValue &&
            currencyFxDateHasValue &&
            foreignPrimeHasValue) ||
          (!currencyCodeHasValue &&
            !currencyFxDateHasValue &&
            !foreignPrimeHasValue)
        );
      },
      internalOnlyFieldsValid: ({
        currency_label,
        currency_effective_date,
        foreign_prime_cost_per_case,
        a_s_review_fl,
        const_only_fl,
        reason,
        costing_event_comment
      }: UploadedPriceRequest) => {
        if (user.type === UserTypeEnum.INTERNAL) return true;

        return (
          !fieldHasValue(currency_label) &&
          !fieldHasValue(currency_effective_date) &&
          !fieldHasValue(foreign_prime_cost_per_case) &&
          !fieldHasValue(a_s_review_fl) &&
          !fieldHasValue(const_only_fl) &&
          !fieldHasValue(reason) &&
          !fieldHasValue(costing_event_comment)
        );
      }
    };
  }, [pcDates, wppDates, getProductInfo, user.type, currencyOptions]);
};

export default useFieldValidation;
