import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import { useAuth } from '../../contexts/AuthenticationProvider';
import CurrencyProvider from '../../contexts/CurrencyProvider';

import {
  useSubmitAllWorkingGroupPriceRequestsMutation,
  useGetDraftPriceRequestsMutation
} from '../../services/PriceRequest';

import {
  setSelectedProducts,
  setWorkingGroupProducts
} from '../../reducers/WorkingGroupSlice';

import { triggerActivityMonitorReset } from '../../reducers/ActivityMonitorSlice';

import {
  setIsAlertVisible,
  setDraftsExist,
  setOpenDeleteModal,
  setBannerNewPriceRequest,
  setRefreshDrafts,
  setBanner,
  setOpenProductDetailsModal
} from '../../reducers/PriceRequestSlice';

import { setProductPriceHistory } from '../../reducers/ProductPriceHistorySlice';

import {
  setOpenBulkCSVModal,
  resetState
} from '../../reducers/CSVBulkUploadSlice';

import {
  fetchApiData,
  handleApiResponse,
  getSessionToken,
  getSkuCardLocation,
  resetSkuCardLocation
} from '../../helpers';

import {
  CalendarDate,
  PriceRequestType,
  ProductType
} from '../../types/productTypes';

import ArrowRight from '../../components/Icons/ArrowRight';
import Banner from '../../components/Banner/Banner';
import HelmetContainer from '../../components/HelmetContainer/HelmetContainer';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Checkmark from '../../components/Icons/CheckMark';
import CSVDownload from '../../components/CSVDownload/CSVDownload';
import CSVErrorBanner from '../../components/CSVErrorBanner/CSVErrorBanner';
import CSVErrorTable from '../../components/CSVErrorTable/CSVErrorTable';
import Delete from '../../components/Icons/Delete';
import DeleteModal from '../../components/Modal/DeleteModal/DeleteModal';
import ExclamationPoint from '../../components/Icons/ExclamationPoint';
import GarbageCan from '../../components/Icons/GarbageCan';
import Heading from '../../components/Heading';
// import IconTooltip from '../../components/IconTooltip';
import LoadingOverlay from '../../components/LoadingOverlay';
import Modal from '../../components/Modal';
import PaperAirplane from '../../components/Icons/PaperAirplane';
import ProductCard from '../../components/ProductCard/ProductCard';
import ProductDetailsModal from '../../components/Modal/ProductDetailsModal/ProductDetailsModal';
import ParseCSVModal from '../../components/Modal/ParseCSVModal/ParseCSVModal';
import Text from '../../components/Text';
import TextLink from '../../components/TextLink';
import UploadDocument from '../../components/Icons/UploadDocument';
import UploadModal from '../../components/Modal/UploadModal/UploadModal';

import copy from '../../config/translations/en-CA.json';

import styles from '../index.module.scss';

const PriceRequestPage = () => {
  const authState = useAuth().authState;
  //assign the imported useNavigate hook from React Router to navigate, which is used in the handleSubmitAll button click below.
  const navigate = useNavigate();
  const LinkToResultsPage = () => navigate('/price-request-list');

  const modalCopy = copy.modals[0].deleteModal.deleteAll;
  const pageCopy = copy.priceRequestPage;

  const [
    postAllPriceRequest,
    { isLoading: isPostAllRequestLoading, isSuccess }
  ] = useSubmitAllWorkingGroupPriceRequestsMutation();

  const [
    getDraftPriceRequests,
    {
      data: draftData,
      isLoading: draftIsLoading,
      isSuccess: draftIsSuccess,
      isError: draftIsError
    }
  ] = useGetDraftPriceRequestsMutation();

  //Using redux, get access to the working group related pieces of state
  const { workingGroupProducts } = useSelector(
    (state: RootState) => state.workingGroup
  );

  const { isValidating, showErrorBanner, showErrorTable } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  const {
    draftsExist,
    openDeleteModal,
    bannerNewPriceRequest,
    refreshWorkingGroupDrafts,
    openProductDetailsModal
  } = useSelector((state: RootState) => state.priceRequest);

  // get access to the useDispatch hook
  const dispatch = useDispatch();

  // get session token
  const token = getSessionToken();

  const { user } = authState;

  const [dates, setDates] = useState<CalendarDate[]>([]);

  const workingGroupEmpty = workingGroupProducts.length === 0;

  useEffect(() => {
    //create an API call to fetch all of the calendar date information
    // needs the token stored in sessionStorage for the Authorization Bearer token
    async function getCalendarDates(accessToken: string | null) {
      try {
        dispatch(triggerActivityMonitorReset(true));
        const result = await fetchApiData(
          'future-calendar-dates',
          accessToken,
          'GET'
        );
        const calendarResponse = await handleApiResponse(result);
        // if there is a response, update the date state with the response.
        if (calendarResponse) {
          setDates(calendarResponse);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (token) {
      getCalendarDates(token);
    }
  }, [token]);

  //Memoize the result of the API Call
  const cachedDate = useMemo(() => dates, [dates]);

  const getPrIds = () => {
    const prIds = draftData
      ?.filter((dt) => !!dt.priceRequest)
      .map((pr) => pr.priceRequest.map((pr) => pr.id))
      .flat(1);
    return prIds;
  };
  //Extra protection to make sure each sku is only rendered once in the working group list.

  const requestBodySubmitPriceRequests = useMemo(() => {
    return {
      prIds: getPrIds()
    };
  }, [draftData]);

  const requestBody = useMemo(() => {
    return {
      skus: workingGroupProducts
    };
  }, [workingGroupProducts]);

  //If there are no product skus in the working group, show the empty screen display by setting workingGroupEmpty to true.
  //otherwise use the POST price-request/draft-by-products API to return all the price request data for the product skus in working group.

  useEffect(() => {
    if (isSuccess) {
      // after a successful submission:
      // clear the working group
      dispatch(setWorkingGroupProducts([]));
      dispatch(setSelectedProducts([]));
      //set the alert is visiable to true so it dispalys on the Price Request List Page
      dispatch(setIsAlertVisible(true));
      dispatch(setRefreshDrafts(true));
      dispatch(
        setBanner({
          title: 'Your Price Requests have been Submitted.',
          type: 'success',
          description: `A confirmation email will be sent to ${user.email} unless otherwise specified (LDB only)`
        })
      );
      // Reset CSV Upload state - clears existing error table data
      dispatch(resetState());
      //redirect to the Price Request List page
      LinkToResultsPage();
    }
  }, [isSuccess]);

  const handleSubmitAll = () => {
    //pass the array of product skus and the token to the postAllPriceRequest mutation.
    postAllPriceRequest({ requestBody: requestBodySubmitPriceRequests, token });
  };

  useEffect(() => {
    if (draftData && draftData.length > 0) {
      draftData.map((priceRequest: ProductType) => {
        if (priceRequest.priceRequest.length > 0) {
          const hasDraft = priceRequest.priceRequest.some(
            (request: PriceRequestType) =>
              request.status === 'Draft' || request.status === 'draft'
          );

          dispatch(setDraftsExist(hasDraft));
        }
      });
    } else {
      dispatch(setDraftsExist(false));
    }
  }, [dispatch, draftData]);

  useEffect(() => {
    if (refreshWorkingGroupDrafts && workingGroupProducts.length > 0) {
      getDraftPriceRequests({ requestBody, token });
      setRefreshDrafts(false);
    }
  }, [
    getDraftPriceRequests,
    refreshWorkingGroupDrafts,
    workingGroupProducts,
    requestBody,
    token
  ]);

  useEffect(() => {
    if (!isValidating) {
      dispatch(setRefreshDrafts(true));
    }
  }, [isValidating]);

  useEffect(() => {
    if (draftIsSuccess) {
      dispatch(setRefreshDrafts(false));
      const scrollToSku = getSkuCardLocation();
      scrollToSku?.scrollIntoView({ block: 'center' });
    }
  }, [draftIsSuccess, draftData, dispatch]);

  const isLoading = isPostAllRequestLoading || draftIsLoading;

  //close the product details modal and clear the price history details
  const closeProductDetailsModal = () => {
    dispatch(setOpenProductDetailsModal(false));
    dispatch(setProductPriceHistory([]));
  };

  //render the component using the memoized data data to pass into the modals
  return (
    <CurrencyProvider>
      <Layout>
        <HelmetContainer title={pageCopy.pageTitle} />
        {isLoading && <LoadingOverlay />}
        {draftIsError && (
          <Banner
            type={'error'}
            icon={<ExclamationPoint />}
            title={'Error loading draft price requests'}
            description={'A server error has occured, please try again later'}
          />
        )}
        {showErrorTable ? (
          <CSVErrorTable />
        ) : (
          <>
            <div className={styles.heading}>
              <Heading element="h2" heading3>
                Price Request Working Group
              </Heading>
              <div className={styles.buttonsRight}>
                <Button
                  icon={<GarbageCan />}
                  iconLeft
                  link
                  disabled={workingGroupEmpty}
                  onClick={() => {
                    dispatch(setOpenDeleteModal(true));
                    resetSkuCardLocation();
                  }}
                >
                  {modalCopy.openModalButton}
                </Button>
                <Button
                  icon={<PaperAirplane />}
                  iconLeft
                  disabled={!draftsExist}
                  primary
                  onClick={() => {
                    handleSubmitAll();
                    resetSkuCardLocation();
                  }}
                >
                  Submit Price Requests
                </Button>
              </div>
            </div>
            <div className={styles.subHeadings}>
              <TextLink
                icon={<ArrowRight />}
                iconRight
                secondary
                button
                route="/product-search"
                onClick={() => {
                  {
                    resetSkuCardLocation();
                  }
                }}
              >
                Add Products
              </TextLink>
              <div className={styles.buttonContainer}>
                <div className={styles.headingToolTip}>
                  <Heading element="h5">Bulk Price Request</Heading>
                  {/*hide the Bulk Price Request Instructions Icon because the Instructions Modal (EPP-190: Bulk Price Request Instructions Modal) is now POST-MVP.
                   <IconTooltip
                    id="bulk-request-tooltip"
                    content="instructions"
                  /> */}
                </div>
                <CSVDownload
                  productData={draftData}
                  isDisabled={workingGroupEmpty}
                />
                <Button
                  secondary
                  icon={<UploadDocument />}
                  iconLeft
                  onClick={() => {
                    dispatch(setOpenBulkCSVModal(true));
                    resetSkuCardLocation();
                  }}
                  data-testid={'upload-button'}
                >
                  Upload
                </Button>
              </div>
            </div>
            {showErrorBanner && <CSVErrorBanner />}
            {bannerNewPriceRequest && (
              <Banner
                type={bannerNewPriceRequest.type}
                icon={
                  bannerNewPriceRequest.type === 'success' ? (
                    <Checkmark />
                  ) : (
                    <Delete />
                  )
                }
                title={bannerNewPriceRequest.title}
                description={bannerNewPriceRequest.description}
                closeable
                onClose={() => dispatch(setBannerNewPriceRequest(undefined))}
              />
            )}
            {workingGroupEmpty ? (
              <Card xlarge>
                <div className={styles.cardHeadingContainer}>
                  <Heading element="h4" heading4>
                    Your Working Group is Empty
                  </Heading>
                  <Text element="p">Add products to get started</Text>
                </div>
                <TextLink
                  children="Add Products"
                  route="/product-search"
                  button
                  icon={<ArrowRight />}
                  iconRight
                />
              </Card>
            ) : (
              <div className={styles.priceRequestWrapper}>
                {draftData &&
                  draftData?.map((product: any, index: number) => {
                    return (
                      <ProductCard
                        key={`${product.name}-${index}`}
                        product={product}
                        user={user?.type}
                        dates={cachedDate}
                      />
                    );
                  })}
                <Modal
                  isOpen={openProductDetailsModal}
                  onRequestClose={closeProductDetailsModal}
                  closeable
                >
                  <ProductDetailsModal />
                </Modal>
              </div>
            )}
            <Modal
              isOpen={openDeleteModal}
              onRequestClose={() => dispatch(setOpenDeleteModal(false))}
              alert
              closeable={false}
            >
              <DeleteModal
                title={modalCopy.title}
                description={modalCopy.description}
              />
            </Modal>
            <UploadModal />
            <ParseCSVModal />
          </>
        )}
      </Layout>
    </CurrencyProvider>
  );
};

export default PriceRequestPage;
